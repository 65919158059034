.bullet-title {
  font-size: 1.2rem;
  font-weight: 600;
  height: 2.7rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0.5rem 1rem; }
  .bullet-title > .title {
    color: var(--color-gs100);
    position: relative;
    width: 100%;
    height: 1.7rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: uppercase;
    padding-left: 0.9rem; }
    .bullet-title > .title::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0.2rem;
      width: 0.4rem;
      height: 1.3rem;
      background-color: #b366ff;
      border-radius: 0.25rem; }
