html,
body,
#root,
#app {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  font-size: 10px;
  outline: none;
  overflow: hidden;
  font-family: 'Open Sans Regular';
  background-color: var(--color-grayscale-gs00); }

#app {
  height: calc(100% - var(--statusbar-height));
  margin-top: var(--statusbar-height); }
  #app.bg .skbar {
    background-color: unset; }
  #app.settings {
    background-color: #ffffff; }
  #app.bright {
    background-color: #ffffff; }
    #app.bright .skbar {
      background-color: var(--color-gs20); }
      #app.bright .skbar .sk-button {
        color: var(--softkey-color); }

.content {
  width: 100%; }

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

.skbar {
  background-color: var(--color-gs00); }

.panel.hide {
  display: none; }
  .panel.hide[data-name='page'] {
    display: block; }

.AppList h1 {
  width: 100%;
  text-align: center;
  height: 4rem;
  background-color: #696bff;
  font-size: 1.7rem;
  color: var(--color-gs00);
  margin: 0;
  padding: 4rem 0 0 0; }

.dialog-container .dialog {
  bottom: var(--softkeybar-height) !important; }
  .dialog-container .dialog > .header {
    text-transform: capitalize; }
  .dialog-container .dialog > .content {
    width: calc(100% - 2rem); }

.update-dialog {
  width: 100%;
  height: 17rem;
  background-color: #e9e9e9;
  position: fixed;
  top: calc(100% - 17rem);
  text-align: center;
  color: #000000;
  font-size: 1.2rem;
  opacity: 1;
  z-index: 1000; }
  .update-dialog.hide {
    top: 100%;
    opacity: 0; }

.panel-cover-soft-key-bar {
  z-index: 100000;
  position: fixed;
  height: calc(100% - var(--statusbar-height));
  width: 100vw;
  top: var(--statusbar-height); }

.with-padding-bot::after {
  content: '';
  height: 4rem;
  width: 100%;
  display: block; }
