.AppsView {
  width: 100%;
  overflow: hidden;
  --category-tabs-height: 3.6rem;
  display: flex;
  flex-flow: column;
  height: 100%; }
  .AppsView .ListviewBox {
    height: calc(100% - var(--category-tabs-height));
    overflow: hidden; }
  @media only screen and (orientation: landscape) {
    .AppsView .ListviewBox {
      height: 16rem; } }
