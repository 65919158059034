.marquee {
  display: inline-flex;
  align-content: flex-start; }
  .marquee .marquee_content {
    display: inline-block; }
    .marquee .marquee_content:first-child {
      margin-inline-end: 5rem; }

@keyframes marquee-animation {
  0% {
    transform: translate(0, 0); }
  100% {
    transform: translate(calc(-50% - 25px)); } }

@keyframes marquee-animation-rtl {
  0% {
    transform: translate(0, 0); }
  100% {
    transform: translate(calc(-50% + 25px)); } }
