.option-menu-container .option-menu .header {
  box-sizing: border-box;
  background-color: transparent;
  color: var(--color-gs00);
  text-align: left;
  font-size: 1.7rem;
  font-weight: bold;
  margin: 2.2rem 1rem 0.6rem;
  padding: 0; }

.option-menu-container .option-menu .content .menu-item {
  box-sizing: border-box;
  min-height: 4.2rem;
  padding: 0; }
  .option-menu-container .option-menu .content .menu-item .content {
    display: flex;
    align-items: center;
    background-color: transparent;
    padding: 0 1rem;
    overflow: hidden; }
  .option-menu-container .option-menu .content .menu-item .content_text {
    font-weight: 600; }
  .option-menu-container .option-menu .content .menu-item .marquee_content {
    margin-top: 0;
    margin-bottom: 0; }
  .option-menu-container .option-menu .content .menu-item [data-icon]::after {
    content: none; }
  .option-menu-container .option-menu .content .menu-item [data-icon]:before {
    font-family: 'gaia-icons';
    content: attr(data-icon);
    display: inline-block;
    font-weight: 500;
    font-style: normal;
    text-decoration: inherit;
    text-transform: none;
    font-size: 3.2rem;
    left: 0;
    color: var(--color-gs100);
    padding-right: 0.8rem; }
  .option-menu-container .option-menu .content .menu-item .checked[data-icon]:after {
    color: var(--highlight-color); }
  .option-menu-container .option-menu .content .menu-item:focus {
    background-color: var(--highlight-color);
    color: white; }
    .option-menu-container .option-menu .content .menu-item:focus .content,
    .option-menu-container .option-menu .content .menu-item:focus .checked[data-icon]:after {
      color: var(--color-gs00); }
    .option-menu-container .option-menu .content .menu-item:focus [data-icon]:before {
      color: var(--color-gs00); }

html[dir='rtl'] .option-menu-container .option-menu .content .menu-item [data-icon='radio-on']:after {
  right: auto;
  left: 0; }

html[dir='rtl'] .option-menu-container .option-menu .content .menu-item [data-icon='radio-off']:after {
  right: auto;
  left: 0; }
