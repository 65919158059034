.category-tabs {
  overflow-x: auto;
  visibility: hidden; }
  .category-tabs .container {
    height: var(--category-tabs-height);
    display: inline-flex;
    padding: 0 0.5rem;
    visibility: visible; }
  .category-tabs .tab {
    font-size: 1.2rem;
    font-weight: 600;
    margin: 0.8rem 0.5rem 1.1rem;
    display: flex;
    align-items: center; }
    .category-tabs .tab.selected {
      font-size: 1.4rem;
      border-radius: 13px;
      padding: 0.3rem 0 0.4rem;
      background-color: rgba(128, 0, 255, 0.15);
      margin: 0.3rem 0.5rem 0.7rem;
      height: 2.6rem;
      box-sizing: border-box; }
      .category-tabs .tab.selected:first-child {
        padding-inline-start: 1.2rem; }
      .category-tabs .tab.selected:last-child {
        padding-inline-end: 1.2rem; }
      .category-tabs .tab.selected::before, .category-tabs .tab.selected::after {
        font-family: 'gaia-icons';
        font-size: 2rem;
        font-weight: normal;
        color: rgba(89, 0, 179, 0.25);
        width: 2rem; }
      .category-tabs .tab.selected:not(:last-child)::after {
        content: 'forward';
        margin-left: 0.3rem; }
      .category-tabs .tab.selected:not(:first-child)::before {
        content: 'back';
        margin-right: 0.3rem; }
    .category-tabs .tab > span {
      white-space: nowrap; }

html[dir='rtl'] .category-tabs .tab.selected:not(:last-child)::after {
  content: 'back'; }

html[dir='rtl'] .category-tabs .tab.selected:not(:first-child)::before {
  content: 'forward'; }
