.AppListView {
  display: block;
  height: 100%;
  overflow: hidden; }
  .AppListView .searchListBox {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: flex-start; }
    .AppListView .searchListBox.showingMessageAndApps .AppListView,
    .AppListView .searchListBox.showingMessageAndApps .searchMsgBox {
      height: auto; }
    .AppListView .searchListBox .searchMsgBox {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center; }
      .AppListView .searchListBox .searchMsgBox i {
        padding-left: 1rem;
        font-size: 2.5rem; }
      .AppListView .searchListBox .searchMsgBox p {
        padding: 1.2rem 1rem 1.3rem 1rem;
        text-align: left;
        margin: 0;
        box-sizing: border-box; }
    .AppListView .searchListBox .searchResults {
      display: block;
      padding: 0;
      outline: none;
      height: 24rem;
      animation: 0.2s linear fade-in; }

@keyframes fade-in {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }
  .AppListView .empty-tips .image {
    background: url(/v1.10.0-test/dist/1f5840eb881257caa1c0a4967c635b67.svg) center center no-repeat;
    width: 100%;
    height: 9.6rem;
    padding-top: 2rem;
    padding-bottom: 1rem; }
  .AppListView .empty-tips .tips {
    text-align: center;
    font-size: 1.7rem; }
