.update-btn {
  box-sizing: border-box;
  width: 22rem;
  height: 3.6rem;
  outline: none;
  border-radius: 100px;
  margin: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid var(--color-gs45); }
  .update-btn > div {
    font-size: 1.4rem;
    color: var(--color-gs100);
    font-weight: 600; }
  .update-btn:focus {
    background: var(--color-purple);
    border: none; }
    .update-btn:focus > div {
      color: var(--color-gs00); }

@media only screen and (orientation: landscape) {
  .update-btn {
    width: 30rem; } }
