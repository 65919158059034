.option-menu-container {
  z-index: 9999;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  border-top: var(--statusbar-height, 2.6rem) solid transparent;
  background-color: rgba(0, 0, 0, 0.85); }
  .option-menu-container .option-menu {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: 0;
    left: 0;
    max-height: 100%; }
    .option-menu-container .option-menu .header {
      padding: 0.5rem 1rem;
      background-color: var(--color-gs20);
      color: var(--color-gs90);
      text-align: center; }
    .option-menu-container .option-menu .container {
      padding-top: 0.3rem;
      background: #e9e9f2; }
      .option-menu-container .option-menu .container > .content {
        max-height: 24rem;
        overflow: hidden;
        position: relative; }
        .option-menu-container .option-menu .container > .content::after {
          content: '';
          height: 4rem;
          width: 100%;
          display: block; }
        .option-menu-container .option-menu .container > .content.with-indicator::before {
          content: '';
          background-image: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.2));
          width: 100%;
          height: 4rem;
          position: fixed;
          top: 8rem;
          left: 0; }
    .option-menu-container .option-menu .content {
      background-color: var(--color-grayscale-gs00);
      color: var(--color-gs90); }
      .option-menu-container .option-menu .content .menu-item {
        min-height: 4.8rem;
        display: flex;
        align-items: center;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        box-sizing: border-box; }
        .option-menu-container .option-menu .content .menu-item .icon {
          width: 3.2rem;
          height: 3.2rem; }
        .option-menu-container .option-menu .content .menu-item .content {
          background-color: transparent;
          padding: 0 1rem;
          overflow: unset; }
        .option-menu-container .option-menu .content .menu-item .content_text {
          margin: 0;
          color: var(--color-gs100);
          overflow: hidden;
          white-space: nowrap; }
        .option-menu-container .option-menu .content .menu-item .content_icon {
          flex: 0 0 4rem; }
        .option-menu-container .option-menu .content .menu-item [data-icon]:before {
          content: none; }
        .option-menu-container .option-menu .content .menu-item [data-icon]:after {
          font-family: 'gaia-icons';
          content: attr(data-icon);
          display: inline-block;
          font-weight: 500;
          font-style: normal;
          text-decoration: inherit;
          text-transform: none;
          font-size: 3.2rem;
          position: absolute;
          right: 0;
          color: var(--color-gs45); }
        .option-menu-container .option-menu .content .menu-item .checked[data-icon]:after {
          color: var(--highlight-color); }
        .option-menu-container .option-menu .content .menu-item:focus {
          background-color: var(--highlight-color);
          color: white; }
          .option-menu-container .option-menu .content .menu-item:focus .content,
          .option-menu-container .option-menu .content .menu-item:focus .checked[data-icon]:after {
            color: var(--color-gs00); }
          .option-menu-container .option-menu .content .menu-item:focus .content_text {
            color: white; }

html[dir='rtl'] .option-menu-container .option-menu .content .menu-item [data-icon='radio-on']:after {
  right: auto;
  left: 0; }

html[dir='rtl'] .option-menu-container .option-menu .content .menu-item [data-icon='radio-off']:after {
  right: auto;
  left: 0; }
