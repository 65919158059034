.LinkAppBrick {
  visibility: visible;
  width: 22rem;
  height: 5.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: auto;
  background-position: center center;
  background-repeat: no-repeat;
  background-color: var(--color-gs10);
  border-color: transparent;
  border-width: 0.5rem;
  border-style: solid;
  font-size: 5rem;
  color: var(--color-gs20);
  overflow: hidden; }
  .LinkAppBrick:focus {
    height: 7.4rem;
    outline: none;
    border-color: #7634e4; }
